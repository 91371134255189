<template>

    <!-- <div class="h-full flex flex-col overflow-hidden">

        <div v-if="loading" class="h-8 w-8 loading-spinner-main mx-auto shrink-0"/>

        <div class="h-full max-h-full flex flex-col gap-8 text-xs" v-else>

            <div
                v-if="showCertificateSearch || showCertificateSelector"
                class="flex items-center gap-2"
                stepbar-column-anchor>

                <InputField
                    v-if="showCertificateSearch"
                    size="sm"
                    placeholder="Buscar certificado"
                    v-model="internalSearchKeyword"
                    icon="icon-search"
                    class="max-w-xs"/>

                <InputField
                    v-if="showCertificateSelector"
                    size="sm"
                    v-model="machinesSelected"
                    placeholder="Filtrar por dispositivo"
                    icon="icon-desktop"
                    :items="certificatesByGroupSelector"
                    :multiple="true"
                    class="max-w-xs"/>

            </div>

            <ScrollShadow v-if="filteredCertificates?.length">

                <div
                    class="flex flex-col w-full gap-6 overflow-y-auto scroll pr-2 text-2xs"
                    :class="[{'max-h-[300px]': scrollLimit}]">

                    <div v-for="certificateGroup in CertificatesByGroup" :key="certificateGroup" class="contents">

                        <div
                            class="relative flex flex-col gap-3"
                            :class="[{'pl-6': shared}, {'order-first': certificateGroup.local}]">

                            <div v-if="shared" class="absolute top-0 left-0 h-full w-1.5 bg-second-50 rounded"/>
                            
                            <div v-if="shared" class="flex items-center justify-between text-sm">

                                <div class="flex items-center gap-2">
                                    
                                    <i class="text-xl" :class="certificateGroup.local ? 'icon-desktop' : 'icon-cloud'"/>
                                    <div class="[&_span]:text-xs [&_span]:!text-main-50" v-html="certificateGroup.label"/>
                                
                                </div>

                            </div>

                            <div class="grid gap-1 md:grid-cols-2 xl:grid-cols-3">

                                <div
                                    v-for="certificate in certificateGroup.items" :key="certificate" 
                                    class="group flex flex-col gap-3 text-main-100 bg-main-10 rounded p-3 cursor-pointer border border-second-10 hover:border-second-75 transition-colors"
                                    :class="[{'border-second-100': certificate.selected}, {'!border-cta2-100 !bg-cta2-10': presetCertificate === certificate}]"
                                    @click="this.ToggleCertificateSelection(certificate)">
                                
                                    <div class="flex items-center gap-2">

                                        <i class="icon-certificate text-xl"/>
                                        <p class="truncate">{{ certificate.subject.CN }}</p>

                                        <div class="action-elements flex items-center gap-2 ml-auto">

                                            <Button v-if="presetCertificate === certificate && !certificate.machine"
                                                class="!hidden group-hover:!flex"
                                                text="Desvincular"
                                                variant="outlined"
                                                size="xs"
                                                icon="icon-broken-link"
                                                @click="this.DesvinculateCertificate()"/>

                                            <Button v-if="actionButton && !certificate.machine" v-bind="actionButton" @click="actionButton.callback?.(certificate)" class="opacity-0 group-hover:opacity-100"/>
                                            
                                            <Toggle v-else-if="!actionButton" v-model="certificate.selected"/>

                                            <div v-if="presetCertificate === certificate" class="group-hover:!hidden flex items-center gap-1 text-xs">
                                                
                                                <i class="icon-link"/>
                                                Preseleccionado
                                                
                                            </div>

                                        </div>

                                    </div>

                                    <div class="flex items-center gap-2 justify-between text-main-50 text-2xs">

                                        <p>Caduca: {{ new Date(certificate.notAfter).toLocaleDateString('es-ES') }}</p>

                                        <p>NIF - VAT: {{ certificate.nifVat }}</p>
                                        
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="[*:last-child>&]:hidden h-px w-full bg-main-5/20"/>

                    </div>

                </div>

            </ScrollShadow>

            <div v-else class="w-full flex flex-col gap-6 p-4 rounded bg-second-50/20 border border-second-50/50 overflow-hidden">

                <div
                    class="relative w-[70px] flex items-center justify-center aspect-square rounded-full bg-second-100/50 mx-auto shrink-0 animate-fade cursor-pointer"
                    @click="this.$router.push({ name: 'CertificatesManager' })">

                    <i class="icon-certificate text-3xl text-main-5"/>

                </div>

                <p v-if="noCertsText" class="truncate text-center" v-html="noCertsText"/>

            </div>

        </div>

        <InfoComponent :data="info"/>

    </div> -->

    <!-- ======================= -->

    <v-progress-circular v-if="loading" class="mx-auto" indeterminate></v-progress-circular>

    <v-autocomplete
        v-else-if="selectable"
        class="w-full"
        :label="ItemSelectLabel"
        :items="dynamicData"
        prepend-inner-icon="icon-certificate text-xl mr-3"
        item-title="label"
        chips
        :loading="loading"
        :disabled="loading"
        v-bind="$vuetifyBinding({ multiple })"
        v-model="value"
        hide-details
        :no-data-text="ItemSelectNoDataText"
        variant="outlined"></v-autocomplete>

    <div v-else-if="FilteredDynamicData" class="h-full w-full flex flex-col gap-6 overflow-hidden">

        <div
            v-if="showCertificateSearch || showCertificateSelector"
            class="flex items-center gap-2"
            stepbar-column-anchor>

            <InputField
                v-if="showCertificateSearch"
                size="sm"
                placeholder="Buscar certificado"
                v-model="internalSearchKeyword"
                icon="icon-search"
                class="max-w-xs"/>

            <InputField
                v-if="showCertificateSelector"
                size="sm"
                v-model="machinesSelected"
                placeholder="Filtrar por dispositivo"
                icon="icon-desktop"
                :items="certificatesByGroupSelector"
                :multiple="true"
                class="max-w-xs"/>

        </div>

        <!-- <div class="max-h-full grid grid-cols-1 gap-4 overflow-y-auto pr-1">

            <div
                v-for="group in CertificatesByGroup?.value" :key="group"
                class="w-full flex flex-col border border-main-5/30 bg-main-5/5 rounded-lg"
                :class="{'border-main-5/50': group.open}">

                <div
                    class="flex items-center justify-between gap-3 p-5 cursor-pointer"
                    :class="{'border-b border-main-5/50': group.open}"
                    @click="group.open = !group.open">

                    <div class="flex items-center gap-3">
                                        
                        <i class="text-lg" :class="group.local ? 'icon-desktop' : 'icon-cloud'"/>
                        <div class="[&_span]:text-xs [&_span]:!text-main-30 whitespace-nowrap" v-html="group.label"/>
                    
                    </div>

                    <p class="text-xs">{{ group.items?.length }} certificado/s</p>

                </div>

                <Collapsible :self=true>

                    <div v-if="group.open" class="w-full">

                        <div class="max-h-[300px] w-full grid gap-1.5 md:grid-cols-2 xl:grid-cols-3 text-xs overflow-y-auto scroll p-5">

                            <div
                                v-for="certificate in group.items" :key="certificate"
                                class="w-full flex flex-col gap-3 p-4 border border-main-5/30 bg-main-5 rounded cursor-pointer text-main-100"
                                :class="[{'bg-cta-5': certificate.selected}, {'!border-cta2-100 !bg-cta2-10': presetCertificate === certificate}]"
                                @click="this.ToggleCertificateSelection(certificate)">

                                <div class="flex items-center gap-3">

                                    <i class="icon-certificate text-base mt-0.5"/>
                                    <p class="truncate">{{ certificate.subject.CN }}</p>

                                    <div class="action-elements flex items-center gap-2 ml-auto">

                                        <Button v-if="presetCertificate === certificate && !certificate.machine"
                                            class="!hidden group-hover:!flex"
                                            text="Desvincular"
                                            variant="outlined"
                                            size="xs"
                                            icon="icon-broken-link"
                                            @click="this.DesvinculateCertificate()"/>

                                        <Button v-if="actionButton && !certificate.machine" v-bind="actionButton" @click="actionButton.callback?.(certificate)" class="opacity-0 group-hover:opacity-100"/>

                                        <Toggle v-else-if="!actionButton" v-model="certificate.selected"/>

                                        <div v-if="presetCertificate === certificate" class="group-hover:!hidden flex items-center gap-1 text-xs">

                                            <i class="icon-link"/>
                                            Preseleccionado

                                        </div>

                                    </div>

                                </div>

                                <div class="flex items-center gap-2 justify-between text-main-50 text-2xs">

                                    <p>Caduca: {{ new Date(certificate.notAfter).toLocaleDateString('es-ES') }}</p>

                                    <p>{{ certificate.nifVat }}</p>

                                </div>

                            </div>

                        </div>

                    </div>

                </Collapsible>

            </div>

        </div> -->

        <div class="max-h-full w-full flex flex-col gap-12 overflow-auto pr-1 pb-16" :class="[{'!max-h-[400px]': scrollLimit}]">

            <div v-for="data in CertificatesByGroup?.value" :key="data" class="contents">

                <div v-if="certificates" class="flex flex-col gap-4 rounded">

                    <div class="flex items-center gap-2">
                                        
                        <i class="text-base" :class="data.local ? 'icon-desktop' : 'icon-cloud'"/>
                        <div class="[&_span]:text-xs [&_span]:!text-main-50 whitespace-nowrap" v-html="data.label"/>

                        <div class="h-px w-full bg-current opacity-15"/>
                    
                    </div>

                    <div class="grid gap-1 md:grid-cols-2 xl:grid-cols-3">

                        <div
                            v-for="certificate in data.items" :key="certificate"
                            class="group flex flex-col gap-3 text-main-100 bg-main-10 rounded p-3 cursor-pointer border border-second-10 text-xs hover:border-second-75 transition-colors"
                            :class="[{'border-second-100': certificate.selected}, {'!border-cta2-100 !bg-cta2-10': presetCertificate === certificate}]"
                            @click="this.ToggleCertificateSelection(certificate)">
                        
                            <div class="flex items-center gap-2">

                                <i class="icon-certificate text-xl"/>
                                <p class="truncate">{{ certificate.subject.CN }}</p>

                                <div class="action-elements flex items-center gap-2 ml-auto">

                                    <Button v-if="presetCertificate === certificate && !certificate.machine"
                                        class="!hidden group-hover:!flex"
                                        text="Desvincular"
                                        variant="outlined"
                                        size="xs"
                                        icon="icon-broken-link"
                                        @click="this.DesvinculateCertificate()"/>

                                    <Button v-if="actionButton && !certificate.machine" v-bind="actionButton" @click="actionButton.callback?.(certificate)" class="opacity-0 group-hover:opacity-100"/>

                                    <Toggle v-else-if="!actionButton" v-model="certificate.selected"/>

                                    <div v-if="presetCertificate === certificate" class="group-hover:!hidden flex items-center gap-1 text-xs">

                                        <i class="icon-link"/>
                                        Preseleccionado

                                    </div>

                                </div>

                            </div>

                            <div class="flex items-center gap-2 justify-between text-main-50 text-2xs">

                                <p>Caduca: {{ new Date(certificate.notAfter).toLocaleDateString('es-ES') }}</p>

                                <p>{{ certificate.nifVat }}</p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div v-else class="w-full flex flex-col gap-6 p-4 rounded bg-second-50/20 border border-second-50/50 overflow-hidden">

        <div
            class="relative w-[70px] flex items-center justify-center aspect-square rounded-full bg-second-100/50 mx-auto shrink-0 animate-fade cursor-pointer"
            @click="this.$router.push({ name: 'CertificatesManager' })">

            <i class="icon-certificate text-3xl text-main-5"/>

        </div>

        <p v-if="noCertsText" class="truncate text-center" v-html="noCertsText"/>

    </div>

</template>

<script>
import BaseInputComponent from '@/slango-multiverse/components/inputs/BaseInputComponent'
import Button from '@/slango-multiverse/components/inputs/Button'
import Toggle from '@/slango-multiverse/components/inputs/Toggle'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import ScrollShadow from '@/components/ScrollShadow'
import Modal from '@/slango-multiverse/components/Modal'
import Collapsible from '@/slango-multiverse/components/Collapsible'
import { digitalCertificates, machines } from '@/helpers/APIconnection'
import { desktopAppComunication } from '@/helpers/desktop-controller'
import { globalProperties } from '@/main.js'
import { ref } from 'vue'

import store from '@/store'

export const UtilitiesController = {

    NormalizedInput: function(items, normalize = {}) {

        return items.map(item => {
            
            return {
                
                label: normalize.label ? globalProperties.$getNestedProperty(item, normalize.label) : item,
                value: normalize.value ? globalProperties.$getNestedProperty(item, normalize.value) : item
            }
        })
    },

    GetMachines: async function({ normalizedItems, currentMachine }) {

        this.loading = true

        const response = await machines.get()

        if ( response?.status === 200 ) {

            const items = response.data.items

            if ( this.$embedded && currentMachine ) {
                
                items.unshift({ name: 'En esta máquina', _id: 0 })
                if ( !this.value?.length ) this.value = items[0]
            }

            if ( normalizedItems ) { return UtilitiesController.NormalizedInput(items, { label: 'name' }) }

            return items
        }

        this.loading = true
    },

    GroupCertificatesByMachine: function({ certificates, normalizedItems }) {

        if ( !certificates?.length ) return

        const group = {}

        certificates.forEach(cert => {

            const machine = cert.machine?._id

            if ( !group[machine] ) {

                group[machine] = { label: globalProperties.$noXSS(cert.machine?.name) ?? `Mis certificados <span>( En esta máquina )</span>`, items: [], machine: cert.machine, local: !cert.machine }
            }

            group[machine].items.push(cert)
        })

        const list = Object.values(group)

        if ( normalizedItems ) { return list.map(item => { return { ...item, items: UtilitiesController.NormalizedInput(item.items, { label: 'subject.CN' }) } }) }

        return ref(list)
    },

    GetCertificates: async function({ certificates = [], shared, byMachine, normalizedItems }) {

        this.loading = true

        const query = "?limit=1000"

        let [certs = [], sharedCerts] = await Promise.all([desktopAppComunication("GetCertificatesList"), shared ? digitalCertificates.get(query) : undefined])

        if ( sharedCerts?.status === 200 ) { 

            const serverCertificatesArray = Object.values(sharedCerts.data.items).map(cert => { return { ...cert.data, machine: cert.machine }})
            certs.push(...serverCertificatesArray)
        }
        
        if ( certs?.length ) {

            certs.forEach(cert => {

                cert.selected = false

                if ( !certificates.some(c => (cert.subject.serialNumber === c.subject.serialNumber) && cert.notAfter === c.notAfter && (c.machine?._id === cert.machine?._id)) ) { certificates.push(cert) }
            })
        }

        this.loading = false

        if ( byMachine ) { return UtilitiesController.GroupCertificatesByMachine({ certificates, normalizedItems }) }
        if ( normalizedItems ) { return UtilitiesController.NormalizedInput(certificates, { label: 'subject.CN' }) }

        return certificates
    }
}

export default {

    extends: BaseInputComponent,
    components: { Button, Toggle, InputField, ScrollShadow, Modal, Collapsible }, //eslint-disable-line

    props: {
        
        modelValue: { type: Array, default: () => [] },
        required: { default: true },
        externalSearchKeyword: String, // Search keywords bridge for outer search input
        searchInputField: { type: Boolean, default: true }, // Enable certificates serach input
        scrollLimit: { type: Boolean, default: true }, // Height limit to avoid long certificates lists, true by default for inputs components view
        persistentSelection: { type: Boolean, default: true }, // Save selected certificates across component updates
        presets: Boolean, // Selected certificate for default certificate in webs
        actionButton: Object, // Binded properties for button in local certificates
        machineName: { type: String },
        hashCode: { type: String },
        modalWrapperConfig: Object, // If an object exists, certificate selector component in wrapped in a modal with object properties

        readonly: Boolean,
        certificates: { type: Boolean, default: true },
        shared: { type: Boolean, default: !store.state.embedded }, // Show other machine certificates
        machines: Boolean,
        selectable: { type: Boolean, default: false },
        currentMachine: { type: Boolean, default: true },
    },

    emit: ['on-preset-certificate', 'on-selected-shared-certificate'],

    data: function() {

        return {

            loading: false,
            internalSearchKeyword: undefined,
            selectedSubject: undefined,
            machinesSelected: undefined,

            dynamicData: undefined,
        }
    },

    computed: {

        FilteredDynamicData() {

            const keyword = this.keyword?.toLowerCase() 
            
            return this.dynamicData?.filter(cert => (!this.keyword || cert.subjectString?.toLowerCase().includes(keyword)) && (!this.machinesSelected?.length || (this.machinesSelected.includes(cert.machine?._id || 0))))
        },

        CertificatesByGroup() { return UtilitiesController.GroupCertificatesByMachine({ certificates: this.FilteredDynamicData }) },

        keyword() { return (this.internalSearchKeyword || '') + (this.externalSearchKeyword || '') },

        selectedCertificatesCount() { return this.dynamicData.filter(c => c.selected).length },

        presetCertificate() {

            if ( !this.presets ) return

            this.$emit('on-preset-certificate', this.selectedSubject)

            if ( !this.selectedSubject ) return

            return this.dynamicData.find(cert => cert.subjectString?.includes(this.selectedSubject))
        },

        selectedMachine(){

            const cert = this.dynamicData.find(c => c.selected)
            return cert?.machine?._id ?? ""
        },

        showCertificateSearch() { return this.searchInputField && this.dynamicData?.length > 5 },

        showCertificateSelector() { return this.shared && this.certificatesByGroupSelector?.length },

        certificatesByGroupSelector() {

            if ( !this.dynamicData?.length ) return

            const groups = []

            if (this.$embedded) groups.push({ label: 'Mis certificados en esta máquina', value: 0 })

            this.dynamicData.forEach(cert => {

                if (cert.machine && (cert.machine?.name !== this.machineName && cert.machine?.machineHashCode !== this.hashCode) && !groups.some(g => g.value === cert.machine?._id)) {
                    
                    groups.push({ label: cert.machine?.name, value: cert.machine?._id })
                }
            })

            return groups
        },

        noCertsText() {

            if ( this.dynamicData?.length ) return `No se han encontrado resultados para la búsqueda <b>${this.$noXSS(this.keyword)}</b>`

            else if ( this.$embedded ) return 'No tienes certificados'

            else {

                this.$nextTick(() => { 
                    
                    const element = document.querySelector('#open-desktop-app-link')

                    if ( element ) element.onclick = this.$openTaskRecorder
                })

                return 'No podemos ver tus certificados desde la web, puedes usar la <span id="open-desktop-app-link" class="hover:underline cursor-pointer">aplicación de escritorio</span> para habilitar esta opción.'
            }
        },

        ItemSelectLabel() {

            if ( this.certificates ) return 'Selección de certificados'
            else if ( this.machines ) return 'Selección de dispositivos'

            return 'Selecciona'
        },

        ItemSelectNoDataText() {

            if ( this.certificates ) return 'No hay certificados disponibles'
            else if ( this.machines ) return 'No hay dispositivos compartidos disponibles'

            return 'Selecciona'
        }
    },

    watch: {

        modelValue() {

            if ( Array.isArray(this.modelValue) ) { this.$emit('on-selected-shared-certificate', this.modelValue?.some(c => c.machine)) }
        }
    },

    created() {

        this.GetSelectedCertificate()
        this.GetData()
    },

    methods: {

        GetData: async function() {

            if ( this.loading ) return

            this.loading = true

            if ( this.certificates ) {

                this.dynamicData = await UtilitiesController.GetCertificates.bind(this)({ shared: this.shared, normalizedItems: this.selectable })
            }

            else if ( this.machines ) {

                this.dynamicData = await UtilitiesController.GetMachines.bind(this)({ normalizedItems: true, currentMachine: this.currentMachine })
            }

            this.loading = false
        },

        UpdateSelectedCertificate: function() {

            if ( !this.dynamicData?.length ) return

            const selected = this.dynamicData.filter(c => c.selected)

            this.value = JSON.parse(JSON.stringify(selected))

            if ( this.persistentSelection ) { store.state.certificates = this.value }
        },

        ToggleCertificateSelection: function(certificate) {

            if ( this.multiple ) { 

                if ( this.selectedMachine && this.selectedMachine !== certificate.machine?._id ){ this.dynamicData.forEach(c => c.selected = false) }
                certificate.selected = !certificate.selected 
            }
            
            else {

                const toggleTo = !certificate.selected

                this.dynamicData.forEach(c => c.selected = false)
                certificate.selected = toggleTo
            }

            this.UpdateSelectedCertificate()
        },

        GetSelectedCertificate: async function () {

            if ( !this.presets ) return

            this.selectedSubject = undefined

            const subject = await desktopAppComunication("GetSelectedDigitalCert")

            if ( !subject ) return

            this.selectedSubject = subject

            return this.selectedSubject
        },

        DesvinculateCertificate: async function () {
            
            await desktopAppComunication("DesvinculateCertificate")

            this.selectedSubject = undefined
        },

        SyncDigitalCertificates: async function(machineName, overwriteMachine = false) {

            if ( !this.dynamicData?.some(c => !c.machine) ) {
                
                this.$createToastMessage({
                        
                    key: 'no-local-certs',
                    text: 'Para sincronizar necesitas al menos un certificado local.',
                    type: 'error',
                    alive: 3000,
                    fixed: true,
                    close: false,
                    leadingIcon: 'icon-certificate'
                })

                return
            }

            const userDigitalCertificatesList = await desktopAppComunication("GetUserDigitalCertificatesList")
            const response = await digitalCertificates.post(`?machineName=${machineName}${(overwriteMachine ? "&overwriteMachine=true" : "")}`, userDigitalCertificatesList)

            if (response.status === 200) { await desktopAppComunication("SetMachineName", machineName) }

            return response
        },

        SerialNumberToCerts: async function(ids) {

            const localCerts = await desktopAppComunication("GetCertificatesList")

            return ids?.map(id => localCerts?.find(cert => cert.serialNumber === id || cert.subjectString === id)).filter(c => c)
        },

        GetCertificatesSerialNumber: function(certs) {

            return certs?.map(cert => cert.serialNumber || cert.subjectString)
        }
    }
}
</script>