<template>

    <div class="h-full w-full flex flex-col gap-5 p-8 pb-0 text-main-5">
		
		<div v-if="title" class="w-full flex items-center flex-wrap gap-3 shrink-0">

			<div>

				<div class="flex items-center gap-3">

					<i v-if="icon" class="cursor-pointer" v-bind="icon"/>
					<p class="text-xl font-medium" v-html="title"/>

				</div>

				<p v-if="description" class="text-xs" v-html="description"/>

			</div>
			
			<div class="grow flex max-sm:flex-wrap items-center justify-end gap-3">

				<slot name="header"/>

				<i v-if="helpButton" class="icon-help text-2xl text-main-5 hover:text-white cursor-pointer"/>

			</div>

		</div>

		<slot name="subheader"/>

		<div v-if="title" class="h-px w-full bg-main-5/50 shrink-0"></div>

		<div class="h-full flex flex-col overflow-hidden">

			<slot/>

		</div>

	</div>

</template>

<script>

export default {

    props: { title: String, description: String, icon: Object, helpButton: Boolean},
}
</script>