<template>

	<div class="h-full lg:w-[90px] shrink-0" focus-group="navigation-menu-controller"></div>

	<div
		class="absolute h-full max-w-full top-0 left-0 bg-main-100 text-main-5 transition-[width,left] duration-300 z-50"
		:class="{'max-lg:!left-[-90px]': !this.store.state.navigationMenuOpen}"
		focus-group="navigation-menu-controller">

		<div class="h-full flex overflow-y-scroll scroll-hidden">

			<!----- Submenu ----->
			<div
				class="flex flex-col transition-[width,opacity] duration-300 text-sm"
				:class="[this.store.state.navigationMenuOpen
					? 'w-[360px]'
					: 'w-0 opacity-0 pointer-events-none']">

				<div class="h-full flex flex-col gap-6 p-4">

					<div class="flex flex-col gap-5">

						<p class="truncate px-2 font-semibold border-b border-main-50 pb-1" v-if="this.currentUser?.userData">{{ this.currentUser.userData.email }}</p>

						<p
							class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]"
							@click="this.$router.push({ name: 'UserProfile' }); this.store.state.navigationMenuOpen = false">
							
							{{ $st('app.account.name') }}
						</p>

						<p
							v-if="!$appConfig.hidden_routes?.includes('/payments')"
							class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]"
							@click="this.$router.push({ name: 'Payments' }); this.store.state.navigationMenuOpen = false">
							
							{{ $st('app.payments.name') }}
						</p>

						<div class="border-t border-dotted border-main-10"/>

						<p class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]" @click="this.user.logout(); this.store.state.navigationMenuOpen = false">{{ $st('app.logout') }}</p>

					</div>

					<SubscriptionCard class="mt-auto self-center"/>

					<p class="text-main-50 text-xs mt-auto">{{ this.store.getters.appVersion }}</p>

				</div>

			</div>

			<div class="h-full w-[90px] flex flex-col items-center gap-3 shrink-0 py-4" ref="sidebar-menu-container">

				<div
					v-for="item in Items" :key="item"
					class="w-full flex flex-col items-center">

					<div
						v-if="item.type === 'button'"
						class="relative group h-[54px] w-16 flex flex-col items-center justify-center gap-1 rounded-md bg-main-5 shadow-material cursor-pointer text-main-100 select-none"
						:class="{'selected-navigation-item': item.isActive?.()}"
						@click="item.onclick?.(); this.store.state.navigationMenuOpen = false">

						<i v-if="item.icon" class="text-2xl" :class="item.icon"/>
						<img v-if="item.image" :src="item.image" class="h-[24px]" alt="">

						<p class="text-[9px] font-bold text-center">{{ this.$st(item.label) }}</p>

						<div
							class="absolute right-[calc(100%+6px)] h-3 w-1 scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 bg-main-5 shrink-0 rounded-xl transition-[height,transform,opacity] duration-300 animate-fade"
							:class="{'!h-8 !scale-100 !opacity-100': item.isActive?.()}"/>

					</div>

					<div
						v-else-if="item.type === 'separator'"
						class="h-px w-2/3 bg-main-5"></div>

				</div>

				<div class="w-full mt-auto !animate-none !opacity-100 px-4">

					<img :src="$appConfig.logotype_white || require('@/assets/icons/slango-logotype-vertical-white.svg')" class="w-full">

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import store from '@/store'
import { user } from '@/helpers/APIconnection'
import SubscriptionCard from '@/components/SubscriptionCard'

export default {

	components: { SubscriptionCard },
  
  computed: {

    currentUser() { return store.getters.currentUser },

	AppSidebarArguments() {

		const items = this.appSidebarArguments.items?.filter(r => !r.hidden?.() && !(r.route && this.$appConfig.hidden_routes?.includes(r.route.path)))

		return { ...this.appSidebarArguments, items: items.reduce((acc, obj, index, array) => {

			if ( obj.type === 'separator' && (!acc[0] || array[index + 1]?.type === 'separator' || index === array.length - 1) ) return acc

			acc.push(obj)

			return acc

		}, [])}
	},

    CustomSidebarArguments() {

		if (store.state.customSidebarArguments?.animation === 'cascade') {

			this.$nextTick(() => {

				const items = this.$refs['sidebar-menu-container']?.children

				if ( !items ) { return }

				items.forEach((item, index) => { item.classList.add('menu-item-animation', 'opacity-0'); item.style['animation-delay'] = (50 * index) + 'ms' })
			})
		}

		return store.state.customSidebarArguments
    },

	Items() {

		const items = this.CustomSidebarArguments ? this.CustomSidebarArguments.items : this.AppSidebarArguments.items

		const normalizedItems = []

		items.forEach(i => {

			if (i.type === 'separator') {

				if (normalizedItems[normalizedItems.length - 1]?.type !== 'separator') normalizedItems.push(i)
				return
			}

			if (i.hidden && i.hidden()) return
			if (this.$appConfig?.hidden_routes && i.route && this.$appConfig.hidden_routes.includes(i.route.path)) return

			normalizedItems.push(i)
		})

		return normalizedItems
	}
  },

	data: function() {

		return {

			user,
			store,
			appSidebarArguments: {

				drawer: true,
				items: [

					{
						type: 'button',
						label: 'app.home.name',
						icon: 'icon-home',
						route: this.$router.resolve({ name: 'Home' }),
						onclick: () => { this.$router.push({ name: 'Home' }) },
						isActive: () => { return this.$route.name === 'Home' }
					},
					{ type: 'separator' },
					// {
					// 	type: 'button',
					// 	label: 'app.workspaces.name',
					// 	icon: 'icon-workspace',
					// 	onclick: () => { this.$router.push({ name: 'Workspace-Assessor' }) },
					// 	isActive: () => { return this.$route.name === 'Workspace-Assessor' },
					// 	hidden: () => !this.$router.resolve({ name: 'Workspace-Assessor' }).meta.allowed?.()
					// },
					{
						type: 'button',
						label: 'app.consultant.name',
						icon: 'icon-flow',
						route: this.$router.resolve({ name: 'FlowsPanel' }),
						onclick: () => { this.$router.push({ name: 'FlowsPanel' }) },
						isActive: () => { return this.$route.name === 'FlowsPanel' }
					},
					{
						type: 'button',
						label: 'Certificados',
						icon: 'icon-certificate',
						route: this.$router.resolve({ name: 'CertificatesManager' }),
						onclick: () => { this.$router.push({ name: 'CertificatesManager' }) },
						isActive: () => { return this.$route.name === 'CertificatesManager' },
						hidden: () => !this.$router.resolve({ name: 'CertificatesManager' }).meta.allowed?.()
					},
					{
						type: 'button',
						label: 'app.altasbajas.name',
						image: require('@/assets/icons/altasbajas.svg'),
						route: this.$router.resolve({ name: 'AltasBajasDashboard' }),
						onclick: () => { this.$router.push({ name: 'AltasBajasDashboard' }) },
						isActive: () => { return this.$route.name === 'AltasBajasDashboard' }
					},
					{
						type: 'button',
						label: 'FIE',
						icon: 'icon-fie',
						route: this.$router.resolve({ name: 'FIE' }),
						onclick: () => { this.$router.push({ name: 'FIE' }) },
						isActive: () => { return this.$route.name === 'FIE' }
					},
					{
						type: 'button',
						label: 'app.notifications.name',
						icon: 'icon-bell',
						route: this.$router.resolve({ name: 'NotificationsDashboard' }),
						onclick: () => { this.$router.push({ name: 'NotificationsDashboard' }) },
						isActive: () => { return this.$route.name === 'NotificationsDashboard' },
						// hidden: () => !this.$router.resolve({ name: 'NotificationsDashboard' }).meta.allowed?.()
					},
					{
						type: 'button',
						label: 'app.roboteka.name',
						icon: 'icon-roboteka',
						route: this.$router.resolve({ name: 'Roboteka' }),
						onclick: () => { this.$router.push({ name: 'Roboteka' }) },
						isActive: () => { return this.$route.name === 'Roboteka' }
					},
					{
						type: 'button',
						label: 'app.recorder.name',
						icon: 'icon-recorder',
						route: this.$router.resolve({ name: 'MacroRecorder' }),
						onclick: () => { this.$router.push({ name: 'MacroRecorder' }) },
						isActive: () => { return this.$route.name === 'MacroRecorder' }
					},
					{
						type: 'button',
						label: 'app.smartbuilder.name',
						icon: 'icon-smart-builder',
						route: this.$router.resolve({ name: 'SmartBuilder' }),
						onclick: () => { this.$router.push({ name: 'SmartBuilder' }) },
						isActive: () => { return this.$route.name === 'SmartBuilder' },
						hidden: () => !this.$router.resolve({ name: 'SmartBuilder' }).meta.allowed?.()
					},
					// { type: 'separator' },
					// {
					// 	type: 'button',
					// 	label: 'Gestor',
					// 	image: require('@/assets/icons/afianza.svg'),
					// 	route: this.$router.resolve({ name: 'DocumentManager' }),
					// 	onclick: () => { this.$router.push({ name: 'DocumentManager' }) },
					// 	isActive: () => { return this.$route.name === 'DocumentManager' },
					// },
					{ type: 'separator' },
					{
						type: 'button',
						label: 'Frumecar',
						image: require('@/assets/icons/frumecar.svg'),
						route: this.$router.resolve({ name: 'Frumecar' }),
						onclick: () => { this.$router.push({ name: 'Frumecar' }) },
						isActive: () => { return this.$route.name === 'Frumecar' },
						hidden: () => !this.$router.resolve({ name: 'Frumecar' }).meta.allowed?.()
					},
					{ type: 'separator', hidden: () => !this.$router.resolve({ name: 'Frumecar' }).meta.allowed?.() },
					{
						type: 'button',
						label: 'app.help.name',
						icon: 'icon-help',
						route: this.$router.resolve({ name: 'Help' }),
						onclick: () => { this.$router.push({ name: 'Help' }) },
						isActive: () => { return this.$route.name === 'Help' },
					},
					// {
					// 	type: 'button',
					// 	label: 'Tutoriales',
					// 	icon: 'icon-form',
					// 	onclick: () => { this.$router.push({ name: 'OnBoarding' }) },
					// 	isActive: () => { return this.$route.name === 'OnBoarding' }
					// },
				]
			}
		}
	},

	methods: {

		IsMenuItemSelected: function(path) {

			this.$route.href === path
		},
	}
}
</script>

<style scoped>
.menu-item-animation {
  animation: menu-item-anim 0.3s ease forwards;
}

@keyframes menu-item-anim {
  from { transform: translateX(-80px) }
  to { transform: translateX(0); opacity: 1; }
}
</style>