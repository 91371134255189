<template>

    <div class="w-full flex flex-col gap-3">

        <div class="w-full flex items-center justify-between gap-6">

            <p class="text-lg font-bold">Historial de robots</p>

            <div class="flex items-center gap-3" v-if="stats">

                <v-chip
                    color="wg"
                    append-icon="icon-flow"
                    :text="`Nuestros robots han trabajado ${stats.totalLogs} veces para ti`"
                    size="small"/>

                <v-chip
                    color="wg"
                    append-icon="icon-diamond"
                    :text="$st('payments.used-slans', { value: stats.slans })"
                    size="small"/>

                <Button
                    v-if="this.logs?.total"
                    variant="outlined_light"
                    size="xs"
                    text="Generar reporte"
                    icon="icon-excel-icon"
                    :loading="this.loading.logReport"
                    @click="this.GetFlowLogsReport()"/>

            </div>

        </div>

        <div class="w-full flex items-center gap-4 mt-4">

            <v-btn-toggle v-model="query.isScheduled" mandatory class="shrink-0" density="compact" color="second">

                <v-btn class="text-xs normal-case tracking-normal">Todos</v-btn>

                <v-btn class="text-xs normal-case tracking-normal">Manuales</v-btn>

                <v-btn class="text-xs normal-case tracking-normal">Programados y remotos</v-btn>

            </v-btn-toggle>

            <Button variant="outlined_light" icon="icon-refresh" size="sm" :loading="loading.flowLogs" @click="GetFlowHistory"/>

            <div class="ml-auto">
                
                <v-pagination
                    v-if="logs?.total"
                    v-model="query.page"
                    totalVisible="4"
                    :length="Math.ceil(logs.total / query.limit)"
                    :disabled="loading.flowLogs"
                    class="w-full [&_span]:!text-xs"
                    density="comfortable"/>

            </div>

        </div>

        <v-data-table
            :headers="headers"
            :items="logs?.items"
            :loading="loading.flowLogs"
            :hover="true"
            :items-per-page="query.limit"
            item-value="_id"
            hide-default-footer
            v-model:expanded="expanded"
            no-data-text="No se han encontrado registros"
            loading-text="Cargando..."
            class="overflow-hidden w-full rounded-lg text-xs [&_tbody_tr:nth-child(odd):not(.exluded-tr)]:bg-main-10 [&_tbody_tr:nth-child(even):not(.exluded-tr)]:bg-main-5 [&_*]:!border-0">

            <template v-slot:item.robot="{ item }"> <!-- eslint-disable-line -->

                <div class="relative flex items-center justify-between gap-3">

                    <div>
                                
                        <p v-if="item.browserFlow">{{ item.browserFlow.name }}</p>

                        <v-chip
                            v-else
                            color="main"
                            text="Este robot ya no forma parte de nuestra familia"
                            size="x-small"/>

                        <p class="w-fit text-[11px] text-main-50 [tr:hover_&]:text-main-100 font-light transition-colors">{{ item.user }}</p>
                                
                    </div>
                            
                    <v-chip
                        :color="item.scheduled ? 'cta' : 'second'"
                        :text="item.executionType"
                        size="x-small"/>

                </div>

            </template>

            <template v-slot:item.result="{ item }"> <!-- eslint-disable-line -->

                <v-chip
                    v-if="isNaN(item.relation)"
                    color="error"
                    text="Error inesperado"
                    size="x-small"/>

                <div v-else-if="item.status === 200 || item.status === 500" class="flex items-center justify-center gap-3 text-xs">

                    <p>({{ item.successfulIterations }}/{{ item.totalIterations }})</p>

                    <div class="flex items-center gap-1">

                        <span class="text-2xs">{{ item.relation }}%</span>
                        <v-progress-circular :model-value="item.relation" :size="13" :width="2" :color="item.relation < 75 ? 'cta2' : 'second'"/>

                    </div>

                </div>

                <v-chip
                    v-else-if="item.status && item.status in FlowDetailsController.infoByStatus"
                    v-bind="FlowDetailsController.infoByStatus[item.status]"
                    size="x-small"/>

            </template>

            <template v-slot:item.button="{ item }"> <!-- eslint-disable-line -->
            
                <v-btn
                    v-if="item.normalizedUserLogStreams?.length"
                    variant="tonal"
                    color="second"
                    :icon="expanded[0] === item._id ? 'icon-chevron-up text-sm' : 'icon-chevron-down text-sm'"
                    density="comfortable"
                    size="small"
                    class="opacity-0 [tr:hover_&]:!opacity-100 transition-none"
                    :class="{'!opacity-100': expanded[0] === item._id}"
                    @click="expanded = expanded[0] === item._id ? [] : [item._id]"/>

            </template>

            <template v-slot:expanded-row="{ item }"> <!-- eslint-disable-line -->

                <tr class="pointer-events-none exluded-tr">

                    <td colspan="100">

                        <div class="w-full flex flex-col items-start gap-3 text-xs pb-3 animate-fade">

                            <v-timeline side="end" density="comfortable" truncate-line="end">

                                <v-timeline-item v-for="msg in item.normalizedUserLogStreams" :key="msg" :dot-color="msg.color" size="x-small">

                                    <p>{{ msg.text }}</p>

                                    <template #opposite>{{ $formatDate({ date: msg.timestamp, format: 'hh:mm:ss' }) }}</template>

                                </v-timeline-item>

                            </v-timeline>

                        </div>

                    </td>

                </tr>

            </template>

        </v-data-table>

    </div>

</template>

<script>
import { flows } from '@/helpers/APIconnection'
import store from '@/store'
import { FlowDetailsController } from '@/components/FlowDetails.vue'

export default {

    components: {},

    data: function() {

        return {

            expanded:[],
            FlowDetailsController,
            store,

            headers: [
                
                {
                    title: 'Robot y lanzamiento',
                    align: 'start',
                    key: 'robot',
                    sortable: false
                },
                {
                    title: 'Fecha y hora',
                    align: 'start',
                    key: 'createdAtString',
                    width: 150
                },
                {
                    title: 'Resultado',
                    align: 'center',
                    key: 'result',
                    sortable: false
                },
                {
                    title: 'Coste',
                    align: 'center',
                    key: 'cost',
                    sortable: false
                },
                {
                    title: '',
                    key: 'button',
                    sortable: false,
                    width: 0,
                    align: 'center'
                }
            ],

            query: {

                sortby: 'createAt',
                sortdir: 'desc',
                page: 1,
                limit: 12,
                isScheduled: 0
            },

            logs: undefined,
            stats: undefined,
            loading: {},
            today: new Date().setHours(0, 0, 0, 0),
        }
    },

    created() {

        this.GetFlowHistory()

        store.state.OnAutopilotExecution = this.GetFlowHistorial
    },

    unmounted() {

        store.state.OnAutopilotExecution = undefined
    },

    computed: {

        Query() { return { ...this.query } } // used to fix reactive problems with watcher
    },

    watch: {

        Query: {

            handler(oldValue, newValue) {

                if ( oldValue.isScheduled !== newValue.isScheduled && this.query.page !== 1 ) {

                    this.query.page = 1
                    return
                }

                this.GetFlowLogs()
            },
            
            deep: true
        }
    },

    methods: {

        GetFlowHistory: function() {

            this.GetFlowLogs()
            this.GetFlowLogsStats()
        },

        GetFlowLogs: async function() {

            if ( this.loading.flowLogs ) return

            this.loading.flowLogs = true

            const { sortby, sortdir, page, limit, isScheduled } = this.query

            let queryString = `?sortby=${sortby}&sortdir=${sortdir}&start=${(page - 1) * limit}&limit=${limit}`

            if ( isScheduled === 1 ) { queryString += '&scheduled=false' }
            else if ( isScheduled === 2 ) { queryString += '&scheduled=true' }

            const response = await flows.getLogs(queryString)

            if ( response?.status === 200 && response.data ) {
                
                response.data.items = Object.values(response.data.items)

                const today = new Date().setHours(0, 0, 0, 0)
                const yesterday = new Date().setHours(-24, 0, 0, 0)
                
                response.data.items.forEach(item => {

                    item.expanded = true

                    FlowDetailsController.NormalizeLogData(item)

                    const createdAt = new Date(item.createAt).setHours(0, 0, 0, 0)

                    if ( createdAt === today ) { item.createdAtString = 'Hoy a las ' + this.$formatDate({ date: item.createAt, format: 'hh:mm' }) }
                    else if ( createdAt === yesterday ) { item.createdAtString = 'Ayer a las ' + this.$formatDate({ date: item.createAt, format: 'hh:mm' }) }

                    return item
                })

                this.logs = response.data
            }

            this.loading.flowLogs = false
        },

        GetFlowLogsStats: async function() {

            const response = await flows.getLogsStats()

            if ( response?.status === 200 && response.data ) { this.stats = response.data }
        },

        GetFlowLogsReport: async function() {

            this.loading.logReport = true

            const response = await flows.getLogsReport(this.filtersAsString ? '?' + this.filtersAsString : '')

            if ( response?.status === 200 ) {

                this.$download({blob: new Blob([Buffer.from(response.data, "base64")], { type: "application/vnd.ms-excel" }), filename: "reporteSlango.xlsx"})
             }

            this.loading.logReport = false
        },
    }
}
</script>