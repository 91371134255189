<template>

    <div class="h-full w-full flex flex-col gap-6 text-white">

        <div class="flex items-center justify-between gap-3 border-b border-main-5/30 pb-4">

            <div>

                <div class="flex items-center gap-3">

                    <i class="icon-previous text-xl cursor-pointer transition-transform hover:-translate-x-0.5" @click="this.onclose"/>
                    <p class="text-lg font-medium">Configuración de empresas y grupos</p>

                </div>

                <!-- <p class="text-xs mt-1">Edita empresas y grupos de trabajadores</p> -->

            </div>

            <div class="flex items-center">

                <Button
                    variant="outlined_light"
                    size="sm"
                    text="Nueva Empresa"
                    icon="icon-certificate"
                    @click="viewConfig.openClientModal(false)"/>

            </div>

        </div>

        <div
            v-if="!this.viewConfig.loading.clients && !filteredClients.length"
            class="p-3 rounded flex items-center justify-center bg-white/10 border border-white/20 text-xs cursor-pointer"
            @click="viewConfig.openClientModal(false)">
            
            Todavía no tienes ninguna empresa registrada.
        </div>

        <div v-else class="flex flex-col gap-1.5 text-xs overflow-y-auto scroll pr-1 pb-6">

            <div v-if="this.viewConfig.loading.clients" class="h-10 rounded bg-white/10 skeleton-anim opacity-60"/>
            <div v-if="this.viewConfig.loading.clients" class="h-10 rounded bg-white/10 skeleton-anim opacity-60"/>

            <div
                v-for="company in filteredClients" :key="company"
                class="w-full bg-main-10 rounded">

                <div class="group h-12 flex items-center bg-main-5 text-main-100 rounded shadow-weak_material cursor-pointer [&_>*]:px-5" @click="company.open = !company.open">

                    <p class="flex items-center gap-3 grow">
                        
                        <i class="icon-certificate text-xl"/>
                        {{ company.fieldsValues.nombreEmpresa }}

                    </p>

                    <p class="grow-[0.2]"><span class="text-2xs text-main-50">CCC |</span> {{ company.fieldsValues.CCC }}</p>
                    <p class="grow-[0.2]"><span class="text-2xs text-main-50">Régimen |</span> {{ company.fieldsValues.Regimen }}</p>

                    <div class="group-hover:opacity-100 opacity-0 flex items-center gap-2 transition-opacity" @click.stop>

                        <i class="icon-edit text-xl opacity-70 hover:opacity-100 transition-opacity" @click="viewConfig.openClientModal(true, company)"/>
                        <i class="icon-trash text-xl opacity-70 hover:opacity-100 transition-opacity" @click="RemoveClient(company)"/>

                        <Button
                            variant="tinted"
                            size="xs"
                            text="Enviar Email"
                            @click="this.SendEmail(company)"/>

                    </div>

                </div>

                <Collapsible :collapsed="!company.open">

                    <div class="flex items-center gap-2 p-3 overflow-x-auto scroll-main"  @wheel="$horizontalScroll">

                        <div
                            class="flex items-center gap-2 py-2 px-4 border border-dashed border-second-100 text-second-100 bg-second-5 hover:bg-second-10 cursor-pointer rounded transition-colors whitespace-nowrap"
                            @click="viewConfig.openGroupModal(false, company)">
                            
                            Nuevo grupo
                            <i class="icon-user text-sm"/>

                        </div>

                        <div
                            v-for="group in FilterCompanyGroups(company.groups)" :key="group"
                            class="group w-fit flex items-center py-2 px-4 bg-second-75 rounded text-main-5 shadow-weak_material cursor-pointer hover:bg-second-100 transition-colors whitespace-nowrap"
                            :class="{'!bg-cta-100': group.selected}"
                            @click="group.selected = !group.selected">
                            
                            {{ group.text }}

                            <div
                                class="w-0 opacity-0 group-hover:w-6 group-hover:opacity-100 flex justify-end transition-[width,opacity]"
                                @click.stop="viewConfig.openGroupModal(true, company, group.key, group.text)">

                                <i class="icon-edit hidden group-hover:flex text-base cursor-pointer hover:underline"/>

                            </div>

                        </div>

                        <Button
                            v-if="company.groups?.filter(g => g.selected)?.length"
                            variant="outlined"
                            size="xs"
                            :text="'Eliminar grupos seleccionados'"
                            icon="icon-trash"
                            class="ml-auto"
                            @click="RemoveGroups(company.groups?.filter(g => g.selected),company)"/>
                    
                    </div>

                </Collapsible>

            </div>

        </div>

    </div>

</template>

<script>
import Button from '@/slango-multiverse/components/inputs/Button'
import Collapsible from '@/slango-multiverse/components/Collapsible'
import { formToFlow } from '@/helpers/APIconnection'
import store from '@/store'

export default {

    props: { onclose: Function, viewConfig: Object },

    components: { Button, Collapsible },

    computed: {

        filteredClients() { return Object.values(this.viewConfig.clients || {}).filter(c => !c.removed) }
    },

    methods: {

        FilterCompanyGroups: function(groups = []) {

            return groups?.filter(g => !g.removed)
        },

        RemoveClient: function(client) {

            if ( !client ) return

            store.state.ui.promptModal = {

                title: "¿ Estás seguro/a ?",
                text: `La empresa “${client.fieldsValues.nombreEmpresa}” va a ser eliminada de tu registro aunque las altas/bajas que hayas generado en ella se mantendrán disponibles en el apartado Altas/Bajas.`,
                footer: [

                    { click: () => store.state.ui.promptModal = undefined, text: "Cancelar", variant: 'outlined' },
                    { click: async () => {

                        const response = await formToFlow.user.client.delete(client.credentials.client)
                        if(response.status == 200){ client.removed = true }

                        store.state.ui.promptModal = undefined

                    }, text: "Eliminar" }
                ]
            }
        },

        RemoveGroups: function(groups,company) {

            if ( !groups ) return

            const text = groups.length > 1
                ? `Los grupos ${groups.map(g => `“${g.text}”`).join(', ')} van a ser eliminados de tu registro aunque las altas/bajas que hayas generado en ellos se mantendrán disponibles en el apartado Altas/Bajas.`
                : `El grupo ${groups.map(g => `“${g.text}”`).toString()} va a ser eliminado de tu registro aunque las altas/bajas que hayas generado en el se mantendrán disponibles en el apartado Altas/Bajas.`

            store.state.ui.promptModal = {

                title: "¿ Estás seguro/a ?",
                text,
                footer: [

                    { click: () => store.state.ui.promptModal = undefined, text: "Cancelar", variant: 'outlined' },
                    { click: async () => {
                        
                        const deletionPromises = groups.map(async g => {
                            const groupKey = Object.keys(company.fields).find(key => company.fields[key].validValues?.some(validValue => validValue.key == g.key));
                            const response = await formToFlow.group.delete(company._id, groupKey, g.key);
                            if (response.status == 200) { g.removed = true; g.selected = false; }
                            return response;
                        });
                        await Promise.all(deletionPromises);

                        store.state.ui.promptModal = undefined

                    }, text: "Eliminar" }
                ]
            }
        },

        SendEmail: function(company){

            if ( !company ) return

            if ( !this.FilterCompanyGroups(company.groups)?.length ) {
                
                company.open = true

                this.$createToastMessage({
                                
                    key: 'no-groups',
                    text: `Necesitas crear al menos un grupo en la empresa ${company.fieldsValues.nombreEmpresa} para continuar.`,
                    type: 'error',
                    alive: 3000,
                    fixed: true,
                    close: false
                })
                
                return
            }

            store.state.ui.promptModal = {

                title: "Enviar email",
                text: `Vas a dar acceso a <b>${company.fieldsValues.email}</b>.`,
                footer: [

                    { click: () => store.state.ui.promptModal = undefined, text: "Cancelar", variant: 'outlined' },
                    { click: async () => {

                        const response = await formToFlow.user.sendemail(company.credentials.client)

                        store.state.ui.promptModal = undefined

                        if ( response?.status === 200 ) {

                            this.$createToastMessage({
                                
                                key: 'success',
                                leadingIcon: 'icon-check',
                                text: `El email se ha enviado correctamente a <b>${company.fieldsValues.email}</b>`,
                                type: 'secondary',
                                alive: 4000,
                                fixed: true,
                                close: false
                            })
                        
                        } else {

                            this.$createToastMessage({
                                
                                key: 'email-error',
                                text: 'Ha ocurrido un error al enviar el correo',
                                type: 'error',
                                alive: 3000,
                                fixed: true,
                                close: false
                            })
                        }

                    }, text: "Enviar" }
                ]
            }

        }
    }
}
</script>