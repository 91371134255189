import { user, install } from '@/helpers/APIconnection' // eslint-disable-line
import { InitializeDefaultTranslation, secureTranslation } from '@/i18n'
import { ref } from 'vue'
import store from '@/store'
import router from '@/router'
import utils from '@/slango-multiverse/helpers/utils'
import { desktopAppComunication, desktopPostMessage, desktopAppData, desktopAddListener, desktopRemoveListener } from '@/helpers/desktop-controller' // eslint-disable-line
import customProtocolCheck from 'custom-protocol-check'
import { PublicClientApplication } from '@azure/msal-browser'
import { marked } from 'marked'

export async function InitApplication(properties) {

    store.state.appGlobalLoading = {}

    properties.$appConfig = store.state.appConfig = {}

    // ===============|  Translations  |===============

    store.state.appGlobalLoading.translations = true
    InitializeDefaultTranslation().then(() => store.state.appGlobalLoading.translations = false)

    // ===============|  Default Redirect On Auth  |===============

    properties.$saveAuthRedirect = (to) => {

        if (!to) { to = properties.$route }

        if ( to.path !== '/' && (!to.redirectedFrom || to.redirectedFrom.path !== '/') ) { store.state.savedFollowingRedirect = to }
    }

    properties.$defaultRedirectOnAuth = () => {

        if ( store.state.savedFollowingRedirect ) {

            router.push(store.state.savedFollowingRedirect)
            return
        }

        const defaultRoute = store.state.currentUser?.userData?.group?.config?.defaultRoute
        let route = { name: 'Root' }

        if ( defaultRoute ) {

            const def = router.resolve({ path: defaultRoute })

            if ( def.matched.some(m => m.path.includes(defaultRoute)) ) route = def
        }

        router.push(route)
    }

    // ===============|  Navigation middleware  |===============

    router.beforeEach(async (to, from, next) => {

        if ( to.meta.debug && process.env.VUE_APP_ENV !== 'dev' ) { next({ path: '/' }); return }
        if ( to.name === 'Auth' ) { store.state.ui = {} }
        else if ( from.name === 'Auth' ) { delete store.state.savedFollowingRedirect }

        if ( from.path === '/' ) { await embeddedInit(to) }

        if ( to.meta.requiresAuth && !store.state.currentUser ) { // >> If next route requires auth check authentication status

            store.state.appGlobalLoading.restoreSession = true

            const sessionRestored = await user.restoreSession()

            store.state.appGlobalLoading.restoreSession = false

            if ( !sessionRestored ) {

                properties.$saveAuthRedirect(to)
                
                next({ name: 'Auth' })
                return
            }
        }

        if ( properties.$appConfig?.hidden_routes?.includes(to.path) || (to.meta.allowed && !to.meta.allowed()) ) {

            next({ path: '/' })
            return
        }

        if ( to.meta.requiresPayment && !store.getters.currentUserSubscription.active ) {

            store.state.ui.promptModal = {

                title: secureTranslation('payments.modals.payment-required.title'),
                text: secureTranslation('payments.modals.payment-required.text'),
                bully: false,
                onclose: () => store.state.ui.promptModal = undefined,
                footer: [
                    { click: () => { router.push({ name: 'Payments' }); store.state.ui.promptModal = undefined }, text: secureTranslation('payments.modals.payment-required.choose-plans'), center: true }
                ]
            }

            next(false)
            return
        }

        next()
    })

    // ===============|  Global methods  |===============

    properties.$changeAppConfig = (config) => {

        if (!config) return

        properties.$appConfig = store.state.appConfig = config
    
        if (config.custom_currency) {
    
            const currency = config.custom_currency.split('|')
    
            config.currency = currency[0]
            config.currencies = currency[1]
        }
    }

    const installDesktopApp = async () => {
                    
        const res = await install.recorder()

        if ( res?.status === 200 ) { properties.$download({ blob: res.data, filename: `${properties.$appConfig.company_name} Desktop Setup`, extension: '.zip' }) }
        store.state.ui.promptModal = undefined
    }
    
    const AttemptToOpenRecorderFailed = () => {
    
        store.state.ui.promptModal = {
    
            title: secureTranslation('app.recorder.installation.title'),
            text: secureTranslation('app.recorder.installation.text'),
    
            footer: [
    
                { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                { variant: 'primary', text: secureTranslation('home.download-installer'), click: installDesktopApp }
            ]
        }
    }

    properties.$st = secureTranslation
    properties.$checkEmbedded = () => {
        
        properties.$withoutAdmin = /SlangoRPA/i.test(navigator.userAgent)
        return store.state.embedded = properties.$embedded = /SlangoDesktop|SlangoRPA/i.test(navigator.userAgent)
    }

    properties.$openExternal = (url, sameTab) => { if ( !url ) return; window.open(url, sameTab ? '_self' : '_blank', 'noopener,noreferrer') }
    properties.$openDesktopApp = (url, failCallback, successCallback, timeout = 2000) => customProtocolCheck(url, failCallback, successCallback, timeout)

    properties.$openTaskRecorder = (showModal = true) => {
        
        if (properties.$checkEmbedded()) { return }
        properties.$openDesktopApp('SlangoTaskRecorder://', showModal ? AttemptToOpenRecorderFailed : installDesktopApp, undefined, 1000)
    }

    properties.$onlyOnDesktop = () => {

        store.state.ui.promptModal = {
    
            title: `Estás usando ${properties.$appConfig.company_name} Web`,
            text: 'Esta acción solo está disponible en nuestra aplicación de escritorio.',
    
            footer: [
    
                { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                { variant: 'primary', text: secureTranslation('home.download-installer'), click: () => properties.$openTaskRecorder(false) }
            ]
        }
    }

    properties.$howToRemoteOnboarding = (type = 'certs') => {

        const content = {

            certs: '<br><div class="flex flex-col items-center text-xs text-center"><i class="icon-desktop text-5xl text-main-60"></i><br>Primero, desde nuestra versión de escritorio, haz público tu dispositivo asignándole un nombre.<br><br><br><br><i class="icon-certificate text-5xl text-main-60"></i><br>Después selecciona un certificado de ese dispositivo con nuestro selector.<br><br><br><br><i class="icon-mr-robot text-5xl text-main-60"></i><br>El robot se encargará del resto utilizando el Piloto Automático en tu versión de escritorio.</div>',
            machine: '<br><div class="flex flex-col items-center text-xs text-center"><i class="icon-desktop text-5xl text-main-60"></i><br>Primero, desde nuestra versión de escritorio, haz público tu dispositivo asignándole un nombre.<br><br><br><br><i class="icon-click text-5xl text-main-60"></i><br>Después selecciona donde quieres lanzar la tarea.<br><br><br><br><i class="icon-mr-robot text-5xl text-main-60"></i><br>El robot se encargará del resto utilizando el Piloto Automático en tu versión de escritorio.</div>'
        }

        store.state.ui.promptModal = {
    
            title: 'Esta acción no está disponible en la versión web',
            text: 'Pero puedes programarla fácilmente para ejecutarse desde la versión de escritorio en estos sencillos pasos:',
            content: content[type],
            onclose: () => store.state.ui.promptModal = undefined,
            bully: false,
            hideCloseButton: true
        }
    }

    properties.$desktopAppComunication = desktopAppComunication
    properties.$desktopPostMessage = desktopPostMessage
    properties.$desktopAddListener = desktopAddListener
    properties.$desktopRemoveListener = desktopRemoveListener
    properties.$debug = console.log
    
    properties.$noXSS = (value) => { return value?.replaceAll(/[<>"'&]/g, c => '&' + ({ '<': 'lt', '>': 'gt', '&': 'amp', "'": 'apos', '"': 'quot' })[c] + ';') }

    properties.$marked = (text) => { return marked.parse(properties.$noXSS(text)) }

    // >> Inputs validation

    properties.$registeredInputs = { all: {} }

    properties.$validate = (group = 'all') => {

        if ( !properties.$registeredInputs ) return true

        const inputs = Object.values(properties.$registeredInputs[group] ?? {})

        return inputs.map(i => {
            
            if ( i.validate ) return i.validate()
            return true
        
        }).every(i => i)
    }

    Object.entries(utils ?? {}).forEach(method => properties['$' + method[0]] = method[1])

    properties.$vuetifyBinding = ({ size = 'md', multiple, label, hintText }) => { // eslint-disable-line

        const data = { multiple, label: label ?? hintText }

        Object.keys(data).forEach(e => { if ( data[e] === undefined ) { delete data[e] } })

        const sizes = {

            xs: { class: 'text-xs', density: 'compact', size: 'x-small' },
            sm: { class: 'text-sm', density: 'comfortable', size: 'small' },
            md: { class: 'text-sm', density: 'comfortable' },
            lg: { class: 'text-base', size: 'large' },
            xl: { class: 'text-base', size: 'x-large' }
        }

        const bind = {

            ...sizes[size],
            ...data
        }

        return bind
    }

    // ==========| Robot logs queue |==========

    properties.$addRobotLogNotification = (log, isNotification = true) => {

        if ( !log?._id ) return

        log.isNotification = isNotification

        if ( !isNotification || !store.state.ui.robotLogs ) store.state.ui.robotLogs = []
        else if ( log._id in store.state.ui.robotLogs ) return

        store.state.ui.robotLogs.push(log)
    }

    // ==========| Toast messages |==========

    const toastMessages = ref({})
    const timeouts = {}

    properties.$toasts = toastMessages.value

    properties.$createToastMessage = (options) => {

        let key = options.key || options.id

        if ( !key ) { key = (options.title ?? '' + options.text ?? '') }
        
        clearTimeout(timeouts[key])
        properties.$toasts[key] = options

        if ( options.alive ) { timeouts[key] = setTimeout(() => delete properties.$toasts[key], options.alive) }
    }

    properties.$getNestedProperty = (object, path) => {

        let current = object
        const steps = path.split('.')

        for (const property of steps) { current = current[property] }
        return current
    }

    properties.$clipboard = (text) => {
        
        if (text) {
            
            navigator.clipboard.writeText(text)
            properties.$createToastMessage({ key: 'clipboard', text: 'Copiado!', alive: 1500, fixed: true, close: false })
        }
    }

    properties.$microsoftLogin = async () => {

        const msalConfig = {

            auth: {
                clientId: '3e77936b-5376-49f8-9f1f-ec34a663a7dd',
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: '/', // La URL a la que se redirigirá después de iniciar sesión
            },
            cache: {
                cacheLocation: "localStorage", // Especifica la ubicación del almacenamiento en caché
                storeAuthStateInCookie: false, // Establece si debe almacenar el estado de autenticación en una cookie
            }
        }

        const msalInstance = new PublicClientApplication(msalConfig)

        await msalInstance.initialize()

        try {

            await msalInstance.loginPopup({ prompt: 'select_account' })

        } catch (error) {

            console.error('Error al iniciar sesión:', error)
        }
    }

    properties.$navigationMenu = (container, current) => {

        if ( !container || !(container instanceof HTMLElement) ) { return }
    
        let underline = container.querySelector('#active-element')

        const children = Array.from(container.children)
    
        if ( !underline ) {
    
            container.classList.add('relative')
            underline = document.createElement('div')
            underline.id = 'active-element'
            underline.classList.add('absolute', 'bottom-0', 'h-0.5', 'bg-current')
            container.appendChild(underline)
        }

        underline.style.transition = 'width 200ms, left 200ms'
    
        current = typeof current === 'string' ? container.querySelector('.' + current) : children[current]

        if ( !current ) return

        children.forEach(c => c.classList.remove('active-element'))
        current.classList.add('active-element')
    
        underline.style.width = current.clientWidth + 'px'
        underline.style.left = current.offsetLeft + 'px'
    }

    properties.$timer = ({ callback = () => {}, interval = 30, autoRestart }) => {

        const timer = ref({ callback })
        let intervalTimeout

        timer.value.remaining = interval
        timer.value.isRunning = false
        timer.value.isPaused = false
        timer.value.isStopped = false

        timer.value.trigger = async () => {

            timer.value.onTrigger?.()
            timer.value.isRunning = false
            await timer.value.callback()
            
            if ( autoRestart && !timer.value.isStopped ) { timer.value.restart() }
        }

        timer.value.pause = () => {
            
            clearInterval(intervalTimeout)
            timer.value.isRunning = false
            timer.value.isPaused = true
        }

        timer.value.restart = () => {

            timer.value.remaining = interval
            timer.value.start()
        }

        timer.value.stop = () => {

            timer.value.isStopped = true
            timer.value.remaining = interval
            clearInterval(intervalTimeout)
        }

        timer.value.start = () => {

            if ( !timer.value.isRunning && timer.value.remaining === 0 ) {

                timer.value.restart()
                return
            }

            clearInterval(intervalTimeout)

            timer.value.isRunning = true
            timer.value.isPaused = false
            timer.value.isStopped = false

            intervalTimeout = setInterval(async () => {

                if ( timer.value.waitForIt ) {
                    
                    timer.value.isPaused = true
                    await timer.value.waitForIt
                    timer.value.isPaused = false
                }

                timer.value.remaining --
    
                if ( timer.value.remaining <= 0 ) {
    
                    clearInterval(intervalTimeout)

                    await timer.value.trigger()

                    return
                }
    
            }, 1000)
        }

        return timer.value
    }

    const embeddedInit = async () => {

        store.state.appGlobalLoading.embedded = true

        // if ( window.name === 'a3pilot' || window.name === 'softwariza3' || /Softwariza3/i.test(navigator.userAgent) || to.query.a3pilot ) {

        //     changeAppConfig(appConfig.softwariza3)
        // }

        if ( properties.$checkEmbedded() ) {

            // check if is necesary update

            const versions = await install.getDesktopVersions(properties.$withoutAdmin? "rpa" : "full")

            if (versions?.status === 200 && versions.data) {

                const versionToDesktop = Object.entries(versions.data).map(([key, value]) => {

                    const [tag, version] = key.split("-")
                    return { tag, version, ...value }
                })

                const checkupdate = await desktopAppComunication("CheckAppUpdate", versionToDesktop) // TODO - Esta comprobación se puede hacer aquí embebiendo en navigator.userAgent la información de la app

                if (checkupdate === "forceUpdate") {

                    store.state.ui.promptModal = {

                        title: "¡Hay disponible una nueva actualización!",
                        text: "Esta actualización es <b>obligatoria</b> y no puedes continuar utilizando la aplicación sin descargarla, obtendrás las últimas mejoras.",
                        footer: [
                            { click: () => { desktopAppComunication("RunInstaller") }, text: "Actualizar", stretch: true, size: "sm" }
                        ]
                    }

                    // Forzar actualización await desktopAppComunication("RunInstaller")

                } else if (checkupdate === "optionalUpdate") { // TODO - In optional updates, if refreshApp, don't ask again

                    store.state.ui.promptModal = {

                        title: "¡Hay disponible una nueva actualización!",
                        text: "Esta actualización es <b>opcional</b> y puedes continuar utilizando la aplicación sin necesidad de descargarla aunque recomendamos que la lleves a cabo para obtener las ultimas mejoras.",
                        footer: [
                            { click: () => store.state.ui.promptModal = undefined, text: "En otro momento", variant: 'outlined', stretch: true, size: "sm" },
                            { click: () => { desktopAppComunication("RunInstaller") }, text: "Actualizar", stretch: true, size: "sm" }
                        ]
                    }
                }
            }

            clearInterval(appStatusInterval)

            // TODO - Change it to listener
            var appStatusInterval = setInterval(async () => { store.state.appStatus = await desktopAppData.AppStatus }, 1000)
        }

        store.state.appGlobalLoading.embedded = false
    }
}