<template>
    
    <div v-if="!containsSlot" class="w-full text-main-100 text-sm">

        <div v-if="directory" class="flex items-center gap-2 bg-main-5 py-2 pl-4 pr-2 rounded shadow-weak_material">

            <div class="contents" v-if="hintText">

                <p class="shrink-0 text-xs">{{ translate(hintText) }}</p>
                <div class="h-6 w-px bg-main-50 shrink-0"/>

            </div>

            <input
                type="text"
                class="h-full w-full border-none bg-transparent outline-none placeholder:text-main-50"
                :placeholder="translate((placeholder || 'general.folderpath'))"
                v-model="value[0]"
                spellcheck="false">
            
            <Button variant="outlined" size="xs" :text="translate('general.browse')" icon="icon-folder" :reversed="true" @click="OpenDialogFolderSelector()"></Button>

        </div>

        <div
            v-else
            class="relative w-full bg-main-5 hover:bg-main-10 hover:shadow-material border border-second-100 text-center rounded transition-[background_box-shadow] duration-300 cursor-pointer"
            :class="{'border-dashed': !value?.length}"
            @click="EventManager"
            @drop.prevent="OnDrop"
            @dragover.prevent="OnDragOver">
            
            <div class="h-14 group relative w-full flex items-center gap-3 py-2 px-4">

                <div class="flex items-center gap-2 opacity-90" v-if="fileExtensionIcons?.length">

                    <div class="h-7 shrink-0" v-for="ext in this.fileExtensionIcons" :key="ext">

                        <img v-if="ext" :src="ext" class="h-full" alt="">

                    </div>

                </div>

                <i v-else class="icon-upload text-xl text-second-100"/>

                <p class="w-full text-start">{{ translate(hintText) }}</p>

                <i v-if="icon" :class="icon"/>

            </div>

            <div v-if="showFiles" class="w-full">

                <div @click.stop v-for="file, index in selectedFiles" :key="file" class="h-9 w-full flex items-center justify-between gap-2 bg-second-10 px-3 border-t border-second-100">
                
                    <p class="text-xs truncate" :title="file.name">{{ file.name }}</p>
                    <i class="icon-cross text-2xs cursor-pointer button-hover" @click="RemoveFile(index)"/>

                </div>

            </div>

        </div>

    </div>

    <div
        v-else
        @click="EventManager"
        @drop.prevent="OnDrop"
        @dragover.prevent="OnDragOver"
        class="contents">

        <slot />

    </div>

    <input type="file" class="hidden"
        ref="input-element-ref"
        :multiple="multiple"
        :accept="normalizedAccept.toString()"
        @change="OnFilesSelect($event.currentTarget.files); $event.currentTarget.value = ''">
        
    <InfoComponent :data="info"/>

</template>

<script>
import BaseInputComponent from './BaseInputComponent.vue'
import safeFile from '@/slango-multiverse/helpers/safeFile'
import Button from '@/slango-multiverse/components/inputs/Button.vue'

import store from '@/store' // eslint-disable-line
import utils from '@/slango-multiverse/helpers/utils'

export default {

    extends: BaseInputComponent,

    components: { Button },

    props: {
        
        modelValue: { type: Array, default: () => [] },
        accept: {},
        hintText: { type: String, default: 'Selecciona tu archivo' },
        maxSize: { type: Number, default: 100000000 },
        directory: Boolean,
        embedded: { type: Boolean, default: () => store.state.embedded },
        showFiles: { type: Boolean, default: true },
    },

    emits: ['input'],

    computed: {

        normalizedAccept() {
    
            const accept = this.accept ?? []
            return Array.isArray(accept) ? accept : accept.split(',')
        },

        selectedFiles() {

            return this.embedded ? this.value?.map(v => { return { name: v } }) : this.value
        },

        fileExtensionIcons: function() {

            const icons = this.normalizedAccept.map(extension => {

                if ( extension === '.xlsx' || extension === '.csv' ) { return require('@/assets/icons/drag-excel.svg') }
                else if ( extension === '.pdf' ) { return require('@/assets/icons/drag-pdf.svg') }
                else if ( extension === '.docx' ) { return require('@/assets/icons/drag-docx.svg') }
                else if ( extension === '.png' || extension === '.jpg' ) { return require('@/assets/icons/drag-img.svg') }
                else if ( extension === '.pptx' ) { return require('@/assets/icons/drag-pptx.svg') }
                else if ( extension === '.txt' ) { return require('@/assets/icons/drag-file.svg') }
            })

            return [...new Set(icons)]
        },

        containsSlot() { return !!this.$slots.default }
    },

    data: function() {

        return {

            utils,
            dragOver: undefined
        }
    },

    created() {

        this.$emit('actionCallback', this.EventManager)
    },

    methods: {

        EventManager: function() {

            if (this.embedded) {

                this.$desktopAppComunication("OpenNativeSelectionDialog", { accept: "", multiple: this.multiple, directory: this.directory })
                .then(r => this.OnFilesSelect(r?.filter(f => this.normalizedAccept.includes('.' + f.split('.').reverse()[0])), false))
                
            } else { this.$refs['input-element-ref'].click() }
        },

        OnDragOver: function() {

            clearTimeout(this.timeout)
            this.dragOver = true
            this.timeout = setTimeout(() => { this.dragOver = false }, 100)
        },

        OnDrop: function(event) {

            if (this.embedded) return

            this.OnFilesSelect(event.dataTransfer.files)
        },

        FilterFilesByExtension: function (filelist) {

            const files = safeFile.secureFiles([...filelist])

            if ( !this.normalizedAccept.length ) { return files }
            
            return files.filter(f => this.normalizedAccept.includes('.' + f?.name.split('.').reverse()[0].toLowerCase()))
        },

        OnFilesSelect: function(files_, filter = true) {

            if ( !files_?.length ) return

            files_ = Array.from(files_)

            if ( !this.embedded ) {

                const size = files_.reduce((acc, current) => acc + current.size, 0)

                if ( size > this.maxSize ) {

                    this.$createToastMessage({
                        
                        key:'size-limit',
                        fixed: true,
                        type: 'error',
                        text: this.$st('scriptlaunch.error.filesizeexceeded', { humanActualSize: this.$formatBytes(size), humanLimitSize: this.$formatBytes(this.maxSize) }),
                        close: false,
                        alive: 3000
                    })

                    return
                }
            }

            const files = filter ? this.FilterFilesByExtension(files_) : files_
            
            if ( !files?.length ) return

            this.$emit('input', files)

            if ( this.showFiles ) { this.value = this.multiple ? [...this.value, ...files] : [files[0]] }
        },

        RemoveFile: function(index) {
            
            this.value.splice(index, 1)
        },

        OpenDialogFolderSelector: async function() {

            if ( this.$embedded ) {

                this.$desktopAppComunication("OpenNativeSelectionDialog", { accept: "", multiple: this.multiple, directory: this.directory })
                .then(r => this.OnFilesSelect(r, false))   
            }
        },
    }
}
</script>